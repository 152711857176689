/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.gjs-one-bg {
  background-color: white !important;
}
.gjs-four-color {
  color: black !important;
}
.gjs-two-color {
  color: black !important;
}
.login-page {
  background: red !important;
  display: flex;
  flex-direction: column;
}
.input:-internal-autofill-selected {
  background-color: #fff !important;
}

.react-chatbot-kit-chat-bot-message {
  background-color: #ec673d !important;
}
.react-chatbot-kit-chat-bot-message-arrow {
  border-right: 8px solid #ec673d !important;
}
.react-chatbot-kit-chat-btn-send {
  background-color: #ec673d !important;
}
.react-chatbot-kit-chat-header {
  display: none !important;
}
.gjs-editor-cont {
  height: 100vh !important;
}

.gjs-sm-properties {
  background: #f3f3f3;
}
.gjs-one-bg {
  background: #f3f3f3 !important;
}
.gjs-three-bg {
  background-color: #f3f3f3;
  color: black;
}
.gjs-clm-tag-status svg,
.gjs-clm-tag-close svg {
  fill: black;
}
.gjs-field {
  background: #ffffff !important;
}
.gjs-sm-sector-label {
  font-weight: 400;
}
.gjs-sm-label {
  font-weight: 400;
}
.gjs-sm-sector-title {
  border: #f3f3f3;
  background: #f3f3f3;
}
.gjs-clm-header-label {
  font-size: 16px;
} */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Raleway" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.gjs-custom-page-manager {
  position: fixed;
  top: 0px;
  left: 0;
  z-index: 3;
}
.gjs-editor-wrapper {
  overflow: hidden;
}
.css-heg063-MuiTabs-flexContainer {
  display: inline-flex;
}
.gjs-left {
  position: absolute;
  left: 0;
  top: 0;
}
.css-hyum1k-MuiToolbar-root {
  min-height: 0px;
}
.gjs-selected {
  outline: 2px solid #3b97e3 !important;
  outline-offset: -2px;
  background: #efeded;
}
.css-1ujykiq-MuiButtonBase-root-MuiTab-root {
  min-width: 0px !important;
}
.css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  background: none;
}
.css-1nrlq1o-MuiFormControl-root {
  vertical-align: middle;
}
.css-qvd6s4-MuiButtonBase-root-MuiTab-root {
  min-width: 0px !important;
}
.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  max-width: 90%;
  margin: 0 auto;
  display: flex;
}
.gjs-cv-canvas__frames {
  background: #b9a5a6 !important;
}
.css-gen84r-MuiTabs-indicator {
  width: 44px;
}
.css-1dnyatc-MuiButtonBase-root-MuiTab-root {
  min-width: 0px !important;
}
.css-1ebbcgr-MuiStack-root {
  background: #f3f3f3;
}
.gjs-clm-tags {
  padding: 10px 12px;
}
.css-to7de5::before {
  display: none !important;
}
